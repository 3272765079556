import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import { clearRedirect } from "../../actions/redirect";
import { tryUserAuth } from "../../actions/user";
import { routeChange } from "../../actions/screen-activities";
import { getAllGroups, getAllProject } from "../../actions/list-view";

import Login from "../login/Login";
import Authorization from "../login/Authorization";

import CONFIG from "../../config";

import MainMenu from "../../components/menus/main-menu/MainMenu";

import ModbusGatewaysListView from "../modbus-gateways/DeviceListView";

import ModbusGatewaysInfoView from "../modbus-gateways/DeviceInfoView";
import ModbusGatewaysChartView from "../modbus-gateways/DeviceChartView";
import ModbusDashboardGatewayListView from "../modbus-gateways/ModbusDashboard";
import ModbusGatewayEdit from "../modbus-gateways/ModbusGatewayEdit";
import ModbusMovePage from "../modbus-gateways/ModbusMovePage";
import ModbusCardView from "../modbus-gateways/ModbusCardView";

import SettingsView from "../settings/SettingsView";
import SettingsMoveGateways from "../settings/SettingsMoveGateways";
import SettingsInfoView from "../settings/SettingsInfoView";
import SettingsMembersView from "../settings/SettingsMembersView";

import WebhookOverview from "../Integration/WebhookOverview";
import WebhookCreate from "../Integration/WebhookCreate";
import WebhookEdit from "../Integration/WebhookEdit";
import WebhookInfo from "../Integration/WebhookInfo";

import Notification from "../notification/notificationModule";
import Integration from "../Integration/Integration";
import ModbusListView from "../modbus-gateways/ModbusListView";
import ModbusGatewaySettingList from "../modbus-gateways/ModbusGatewaySetting";
import BleGatewaysListView from "../ble-gateways/DeviceListView";
import BleGatewaysInfoView from "../ble-gateways/DeviceInfoView";

import BaceEpCardView from "../bace-ep/DeviceCardView";
import BaceEpConfigView from "../bace-ep/DeviceConfigView";
import BaceEpListView from "../bace-ep/DeviceListView";
import BaceEpInfoView from "../bace-ep/DeviceInfoView";
import BaceEpChartView from "../bace-ep/DeviceChartView";
import BaceEpSettingsView from "../bace-ep/DeviceSettingsView";

import ActivityMonitorsCardView from "../activity-monitors/DeviceCardView";
import ActivityMonitorsListView from "../activity-monitors/DeviceListView";
import ActivityMonitorsInfoView from "../activity-monitors/DeviceInfoView";
import ActivityMonitorsSessionView from "../activity-monitors/DeviceSessionView";
import ActivityMonitorsChartView from "../activity-monitors/DeviceChartView";

import HumanMovementScienceListView from "../human-movement-science/DeviceListView";
import HumanMovementScienceInfoView from "../human-movement-science/DeviceInfoView";
import HumanMovementScienceSessionView from "../human-movement-science/DeviceSessionView";
import HumanMovementScienceChartView from "../human-movement-science/DeviceChartView";

import SessionListView from "../sessions/SessionListView";
import SessionChartView from "../sessions/SessionChartView";
import Dashboard from "../dashboard/Dashboard";
import ApiDashboard from "../api-dashboard/ApiDashboard";
import AlertLog from "../alert-log/AlertLog";
import Register from "../register/Register";
import Success from "../register/Success";
import Projects from "../projects/projects";
import axios from "axios";
import WebhookAttempts from "../Integration/WebhookAttempts";
import DeviceMeasurementData from "../bace-ep/DeviceMeasurementData";

const { userLogoutTimeout, baseUrl } = CONFIG;

const publicUrls = ["/"];
class Main extends Component {
  state = {
    authentificated: false,
    projectData: [],
    projectSelected: "",
    projectLabel: "",
    mainData: null,
    subData: null,
    subdataId: 123,
  };

  componentDidMount = () => {
    this.props.routeChange();

    if (!this.props.user) {
      const userLeft = JSON.parse(localStorage.getItem("userLeftTime"));
      if (userLeft) {
        const timeNow = Date.now();
        if (timeNow - userLeft > userLogoutTimeout) {
          // Logout user if tab or browser have been closed more than 4 hours ago
          localStorage.clear();
          return;
        }
      }
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        //
        this.props.tryUserAuth(user);
      } else {
        const authRoutRegExp = new RegExp("^/authorization", "i");
        if (!window.location.pathname.match(authRoutRegExp)) {
          this.props.history.push("/");
        }
      }
    }

    if (
      !window.location.pathname.startsWith("/") &&
      !window.location.pathname.startsWith("/authorization")
    ) {
      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        localStorage.setItem("userLeftTime", Date.now());
        return true;
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.redirectTo &&
      this.props.redirectTo !== prevProps.redirectTo
    ) {
      this.props.history.push(this.props.redirectTo);
      this.props.clearRedirect();
    }

    if (this.props.location !== prevProps.location) {
      this.props.routeChange();
      window.scrollTo(0, 0);
    }

    if (publicUrls.includes(window.location.pathname)) {
      // && this.props.subtypes) {
      if (
        this.props.user?.token?.token &&
        new Date(this.props.user?.token?.expires).getTime() >= Date.now()
      ) {
        if (this.state.projectSelected !== "") {
          this.props.history.push(
            `/dashboard/home/${this.state.projectSelected}`
          );
        }

        if (this.state.projectData.length === 0) {
          this.props.getAllProject();
        }
      }
    }
  };
  handleProject = (langValue) => {
    this.setState({
      projectSelected: langValue,
    });
  };

  clearProject = (langValue) => {
    this.setState({ projectSelected: "", projectData: [] });
  };
  render() {
    //  const { formInput } = this.state;
    const { mainData, subData } = this.state;

    this.state.projectData =
      this.props.allProjectData === undefined
        ? this.state.projectData
        : this.props.allProjectData;
    const projectFinddetails = this.props.allProjectData
      ? this.props.allProjectData.items.filter(
          (h) => h.id_project === this.state.projectSelected
        )
      : "";
    this.state.projectLabel = projectFinddetails[0]?.label;
    if (this.props.user) {
      return (
        <Fragment>
          {this.state.projectData &&
          this.state.projectSelected === "" &&
          this.props.user?.RegisterDataResult === undefined ? (
            <Projects
              projectAllData={this.props.allProjectData}
              onSelectProject={this.handleProject}
              allProjectDetailsData={this.props.allProjectDetailsData}
            />
          ) : (
            <div>
              {" "}
              <MainMenu
                userDetails={this.props.user}
                projectId={this.state.projectSelected}
                projectLabel={this.state.projectLabel}
                clearProject={this.clearProject}
              />
              <div>
                <div
                  className="bg-dashboard"
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <Switch>
                    <Route
                      path="/modbus-gateways/dashboard-view/:currentPageNr?/:projectId?"
                      component={ModbusDashboardGatewayListView}
                    />

                    {/* <Route
                      path="/modbus-gateways/edit-view/:currentPageNr?"
                      component={ModbusGatewayEdit}
                    /> */}
                    <Route
                      path="/modbus-gateways/card-view/:currentPageNr?/:projectId?"
                      component={ModbusCardView}
                    />
                    <Route
                      path="/modbus-gateways/config-view/:currentPageNr?/:projectId?"
                      component={ModbusMovePage}
                    />

                    {/* <Route
                      path="/settings/:currentPageNr?/:projectId?"
                      component={SettingsView}
                    /> */}

                    <Route
                      path="/settings/info-view/:currentPageNr?/:projectId?"
                      component={SettingsInfoView}
                    />
                    <Route
                      path="/settings/moving/:currentPageNr?/:projectId?"
                      component={SettingsMoveGateways}
                    />
                    <Route
                      path="/settings/members/:currentPageNr?/:projectId?"
                      component={SettingsMembersView}
                    />

                    <Route
                      path="/integration/:currentPageNr?/:projectId?"
                      component={Integration}
                    />
                    <Route
                      path="/notification/:currentPageNr?/:projectId?"
                      component={Notification}
                    />

                    <Route
                      path="/webhook/overview/:currentPageNr?/:projectId?"
                      component={WebhookOverview}
                    />

                    <Route
                      path="/webhook/create/:currentPageNr?/:projectId?"
                      component={WebhookCreate}
                    />

                    <Route
                      path="/webhook/edit/:currentPageNr?/:projectId?/:webhookId?"
                      component={WebhookEdit}
                    />

                    <Route
                      path="/webhook/info/:currentPageNr?/:projectId?/:webhookId?"
                      component={WebhookInfo}
                    />

                    <Route
                      path="/webhook/attempts/:currentPageNr?/:projectId?/:webhookId?"
                      component={WebhookAttempts}
                    />
                    <Route
                      path="/modbus-gateways/list-view/:currentPageNr?/:projectId"
                      component={ModbusListView}
                    />
                    <Route
                      path="/modbus-gateways/list-view/:currentPageNr?/:projectId"
                      component={BaceEpListView}
                    />

                    <Route
                      path="/modbus-gateways/select-Gateway/:currentPageNr?/:projectId"
                      component={ModbusGatewaySettingList}
                    />
                    <Route
                      path="/modbus-gateways/:projectId?/:id?/measurement-data"
                      component={ModbusGatewaysChartView}
                    />
                    <Route
                      path="/modbus-gateways/:projectId?/:id?/device-info"
                      component={ModbusGatewaysInfoView}
                    />
                    {/* <Route
                      path="/modbus-gateways"
                      component={ModbusGatewaysListView}
                    /> */}

                    {/*BLE gateways routes*/}
                    <Route
                      path="/ble-gateways/list-view"
                      component={BleGatewaysListView}
                    />
                    <Route
                      path="/ble-gateways/:id?/device-info"
                      component={BleGatewaysInfoView}
                    />
                    {/* <Route path="/ble-gateways" component={BleGatewaysListView} /> */}

                    {/*BACE EP routes*/}
                    <Route
                      path="/bace-ep/list-view/:currentPageNr?/:projectId"
                      component={BaceEpListView}
                    />
                    <Route
                      path="/bace-ep/card-view/:currentPageNr?/:projectId"
                      component={BaceEpCardView}
                    />

                    <Route
                      path="/bace-ep/config-view/:currentPageNr?/:projectId"
                      component={BaceEpConfigView}
                    />
                    <Route
                      path="/bace-ep/:projectId?/:id?/device-info"
                      component={BaceEpInfoView}
                    />
                    <Route
                      path="/bace-ep/:projectId?/:id?/settings"
                      component={BaceEpSettingsView}
                    />

                    <Route
                      path="/bace-ep/:projectId?/:id?/measurement-data"
                      component={DeviceMeasurementData}
                    />
                    <Route
                      path="/bace-ep/:id?/measurement-data"
                      component={BaceEpChartView}
                    />

                    {/*Activity monitors routes*/}
                    <Route
                      path="/activity-monitors/list-view"
                      component={ActivityMonitorsListView}
                    />
                    <Route
                      path="/activity-monitors/:id?/device-info"
                      component={ActivityMonitorsInfoView}
                    />
                    <Route
                      path="/activity-monitors/:id?/session-history/:currentPageNr?"
                      component={ActivityMonitorsSessionView}
                    />
                    <Route
                      path="/activity-monitors/:id?/measurement-data/:sessionId?"
                      component={ActivityMonitorsChartView}
                    />
                    <Route
                      path="/activity-monitors"
                      component={ActivityMonitorsCardView}
                    />

                    {/*Human movement science routes*/}
                    <Route
                      path="/human-movement-science/list-view"
                      component={HumanMovementScienceListView}
                    />
                    <Route
                      path="/human-movement-science/:id?/device-info"
                      component={HumanMovementScienceInfoView}
                    />
                    <Route
                      path="/human-movement-science/:id?/session-history/:currentPageNr?"
                      component={HumanMovementScienceSessionView}
                    />
                    <Route
                      path="/human-movement-science/:id?/measurement-data/:sessionId?"
                      component={HumanMovementScienceChartView}
                    />
                    <Route
                      path="/human-movement-science"
                      component={HumanMovementScienceListView}
                    />
                    {/*Sessions routes*/}
                    <Route
                      path="/sessions/list-view/:currentPageNr?"
                      component={SessionListView}
                    />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route
                      path="/api-dashboard/ApiDashboard"
                      component={ApiDashboard}
                    />
                    <Route path="/alert-log/AlertLog" component={AlertLog} />
                    <Route
                      path="/sessions/:id?/measurement-data/:sessionId?"
                      component={SessionChartView}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Switch>
            <Route path="/authorization" component={Authorization} />
            <Route path="/" exact component={Login} />
            <Route path="/Register" component={Register} />
          </Switch>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    redirectTo: state.redirectReducer.redirectTo,
    allGroups: state.listViewReducer?.allGroups,
    subtypes: state.listViewReducer?.subtypes,
    allProjectData: state.listViewReducer?.allProjectData,
    allProjectDetailsData: state.listViewReducer?.allProjectDetailsData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearRedirect,
    tryUserAuth,
    routeChange,
    getAllGroups,
    getAllProject,
  })(Main)
);
