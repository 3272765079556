import React, { Component, Fragment } from "react";
import modbusPngimage from "../../assets/Icons/modbus.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getListViewData,
  getDeviceData,
  getModbusListViewData,
  getBluetoothListViewData,
} from "../../actions/list-view";
import "./styles.css";
import {
  getSessionListViewData,
  clearSessionListViewData,
} from "../../actions/session-view";
import { getAllModbusDevices } from "../../actions/modbus-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderGear,
  faHardDrive,
  faCircleExclamation,
  faServer,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import TopMenu from "../../components/menus/top-menu/TopMenu";
import CONFIG from "../../config";
import axios from "axios";

const { baseUrl } = CONFIG;
const initialState = {
  modbus: [],
  bacePlusOnline: [],
  bacePlusOffline: [],
  tcpOnline: [],
  tcpOffline: [],
  rtuOnline: [],
  rtuOffline: [],
  projectId: "",
};
class Dashboard extends Component {
  state = initialState;
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(16);
    this.setState({ projectId: projectId });
    if (!currentPageNr) {
      this.props.history.push(`/dashboard/home/${projectId}`);
    }

    this.getdetailes(projectId);

    const filterParams = `filter[id_project]=${projectId}&filter[inactive_at]=NULL`;
    this.props.getListViewData("bace-ep", currentPageNr, "", filterParams);
    this.props.getAllModbusDevices(projectId);
  };

  getdetailes = (projectId) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.userToken}`;
    try {
      axios
        .get(
          `${baseUrl}/physical-device?type=gateway&expand=subDevices,iotDevice&filter[id_project]=${projectId}&filter[is_connected]=1`
        )
        .then((res) => {
          this.setState({ bacePlusOnline: res.data });
        });

      axios
        .get(
          `${baseUrl}/physical-device?type=gateway&expand=subDevices,iotDevice&filter[id_project]=${projectId}&filter[is_connected]=0`
        )
        .then((res) => {
          this.setState({ bacePlusOffline: res.data });
        });

      axios
        .get(
          `${baseUrl}/physical-device?type=asset&filter[id_device_type][like]=modbus-tcp&filter[id_project]=${projectId}&filter[is_connected]=1`
        )
        .then((res) => {
          this.setState({ tcpOnline: res.data });
        });
      axios
        .get(
          `${baseUrl}/physical-device?type=asset&filter[id_device_type][like]=modbus-tcp&filter[id_project]=${projectId}&filter[is_connected]=0`
        )
        .then((res) => {
          this.setState({ tcpOffline: res.data });
        });
      axios
        .get(
          `${baseUrl}/physical-device?type=asset&filter[id_device_type][like]=modbus-rtu&filter[id_project]=${projectId}&filter[is_connected]=1`
        )
        .then((res) => {
          this.setState({ rtuOnline: res.data });
        });
      axios
        .get(
          `${baseUrl}/physical-device?type=asset&filter[id_device_type][like]=modbus-rtu&filter[id_project]=${projectId}&filter[is_connected]=0`
        )
        .then((res) => {
          this.setState({ rtuOffline: res.data });
        });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const projectId = window.location.pathname.slice(16);
      this.props.getModbusListViewData("modbus-gateways", currentPageNr);
      // this.props.getBluetoothListViewData("ble-gateways", currentPageNr);
      // this.props.getSessionListViewData(
      //   currentPageNr,
      //   sortColumn,
      //   sortDirection
      // );
    }
  };

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/sessions")) {
      this.props.clearSessionListViewData();
    }
  };

  render() {
    const {
      bacePlusOffline,
      bacePlusOnline,
      tcpOnline,
      tcpOffline,
      rtuOnline,
      rtuOffline,
    } = this.state;

    const modbusOption =
      this.props.allGatewayDataWithAccessibleProtocol !== undefined
        ? this.props.allGatewayDataWithAccessibleProtocol.includes(
            "modbus-rtu" || "modbus-tcp"
          )
          ? true
          : false
        : false;
    return (
      <Fragment>
        <div className=" pl-2">
          <div className="col pl-4">
            <div style={{ display: "flex" }}>
              <h3 className="p-3 color-ocean-blue modbusDevice">Home</h3>
              <TopMenu />
            </div>{" "}
            <div className="switch-btn-line mb-4"></div>
            {/* <div className="card p-4 intro-bg-color">
              <div className="h6 mb-4">
                Getting Started with BACE Research Portal
              </div>
              <div className="mb-4">
                <h6 className="mb-3">Introduction</h6>
                <p className="submenu-text">
                  Lorem Ipsu m is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>

              <div className="mb-4">
                <h6 className="mb-3">Prerequisties</h6>
                <p className="submenu-text">
                  Lorem Ipsu m is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.{" "}
                </p>
              </div>

              <div className="mb-2">
                <h6 className="mb-2">
                  Step 1. Connect external asset to BACE gateway
                </h6>
                <p className="submenu-text">
                  Lorem Ipsu m is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p>
                <div className="d-flex justify-content-between">
                  <input
                    type="text"
                    className="p-2 bg-secondary m-2 form-control"
                  />
                  <input
                    type="text"
                    className="p-2 bg-secondary m-2 form-control"
                  />
                  <input
                    type="text"
                    className="p-2 bg-secondary m-2 form-control"
                  />
                </div>
              </div>
              <div className="mb-4">
                <h6 className="mb-4">Step 2. Configue device settings</h6>
              </div>
              <br></br>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg p-2 m-2"
                  >
                    Full documentation
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg p-2 m-2"
                  >
                    Next Step
                  </button>
                </div>
                <div className="d-flex">
                  <div className="mb-3 form-check p-2 m-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      Don't Show Again
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg p-2 m-2"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div> */}
            <div className="card-grid ">
              <Link
                to={`/bace-ep/card-view/1/${this.state.projectId}`}
                className="hoverCard bg specs-key clickable divmodbus getwayCard"
              >
                {this.props.listViewData?.items?.length === 0 ? (
                  <div
                    data-toggle="tooltip"
                    data-placement="right"
                    role="tooltip"
                    data-html="true"
                    title="Oops, it seems like no Gateway 
                         has been assigned to your
                         account yet. Please contact 
                         your Evalan representative to 
                         get access to your gateways."
                    className="p d-flex justify-content-end"
                  >
                    <FontAwesomeIcon
                      className="text-danger fa-lg"
                      icon={faCircleExclamation}
                    />
                  </div>
                ) : (
                  <div className="mb-4"></div>
                )}
                <div className="card-data">
                  <p className="gateWayscardheading">BACE Gateways</p>
                  <FontAwesomeIcon className="mb-2 fa-3x" icon={faHardDrive} />
                  {bacePlusOnline && bacePlusOffline ? (
                    <div>
                      <table class="table borderless">
                        <tbody>
                          <tr>
                            <td>
                              <span className="h6">BACE Plus</span>
                            </td>
                            <td> </td>
                            <td> </td>
                            <td>
                              <span className="online">
                                {bacePlusOnline._meta?.totalCount} online{" "}
                              </span>
                            </td>{" "}
                            <td>
                              <span className="offline">
                                {bacePlusOffline._meta?.totalCount} offline{" "}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <span className="mb-2 submenu-specs-value">...</span>
                  )}
                </div>
              </Link>
              {modbusOption && (
                <Link
                  to={`/modbus-gateways/card-view/1/${this.state.projectId}`}
                  className="hoverCard bg specs-key clickable divmodbus"
                >
                  {this.props.allModbusDevices?.items?.length === 0 ? (
                    <div
                      data-toggle="tooltip"
                      data-placement="right"
                      role="tooltip"
                      data-html="true"
                      title="Oops, it seems like no Gateway 
                         has been assigned to your
                         account yet. Please contact 
                         your Evalan representative to 
                         get access to your gateways."
                      className="p d-flex justify-content-end"
                    >
                      <FontAwesomeIcon
                        className="text-danger fa-lg"
                        icon={faCircleExclamation}
                      />
                    </div>
                  ) : (
                    <div className="mb-4"></div>
                  )}

                  <div className="card-data">
                    <p className="gateWayscardheading">Modbus Devices</p>
                    <img src={modbusPngimage} title="modBus device" />
                    {this.props.allModbusDevices ? (
                      <div>
                        <table class="table borderless">
                          <tbody>
                            <tr>
                              <td>
                                <span className="h6">TCP</span>
                              </td>
                              <td> </td>
                              <td> </td>
                              <td>
                                <span className="online">
                                  {tcpOnline._meta?.totalCount} online{" "}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="offline">
                                  {tcpOffline._meta?.totalCount} offline{" "}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="h6">RTU</span>
                              </td>
                              <td> </td>
                              <td> </td>
                              <td>
                                <span className="online">
                                  {rtuOnline._meta?.totalCount} online{" "}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="offline">
                                  {rtuOffline._meta?.totalCount} offline{" "}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <span className="mb-2 submenu-specs-value">
                        Loading...
                      </span>
                    )}
                  </div>
                </Link>
              )}
              {/* <Link
                to="/api-dashboard/ApiDashboard"
                className="bg specs-key clickable"
              > */}
              {/* {this.props.listViewData.items?.length === 0 ?? ( */}
              {/* <div
                  data-toggle="tooltip"
                  data-placement="right"
                  role="tooltip"
                  data-html="true"
                  title="Oops, it seems like no Gateway 
                       has been assigned to your
                       account yet. Please contact 
                       your Evalan representative to 
                       get access to your gateways."
                  className="p d-flex justify-content-end"
                >
                  <FontAwesomeIcon
                    className="text-danger fa-lg"
                    icon={faCircleExclamation}
                  />
                </div>
                <div className="card-data">
                  <p className="">Rest API</p>
                  <FontAwesomeIcon className="mb-4 fa-3x" icon={faFolderGear} />
                  <span className="mb-2 submenu-specs-value"></span>
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionListViewData: state.sessionReducer.sessionListViewData,
    listViewData: state.listViewReducer.listViewData,
    listModViewData: state.listViewReducer.listModViewData,
    listBluetoothViewData: state.listViewReducer.listBluetoothViewData,
    allModbusDevices: state.modbusViewReducer.allModbusDevices,
    userToken: state.userReducer?.token?.token,
    allGatewayDataWithAccessibleProtocol:
      state.listViewReducer.allGatewayDataWithAccessibleProtocol,
    // allProjectData: state.listViewReducer?.allProjectData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSessionListViewData,
    getListViewData,
    getAllModbusDevices,
    getModbusListViewData,
    getDeviceData,
    getBluetoothListViewData,
    clearSessionListViewData,
    //  getAllProject,
  })(Dashboard)
);
